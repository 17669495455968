import React from 'react'
import TempPic from '../assets/protector.jpg';
import AnsysLogo from '../assets/AnsysLogo.png';
import GDnTLogo from '../assets/GDnTLogo.png';
import CLogo from '../assets/CLogo.png';
import CplusLogo from '../assets/CplusLogo.png';
import PythonLogo from '../assets/PythonLogo.png';
import MatlabLogo from '../assets/MatlabLogo.png';
import ArduinoLogo from '../assets/ArduinoLogo.png';
import RaspberrypiLogo from '../assets/RaspberrypiLogo.png';
import TDPrintLogo from '../assets/3DPrintLogo.png';
import SolidworksLogo from '../assets/SolidWorksLogo.png';
import InventorLogo from '../assets/InventorLogo.png';
import Cncmill from '../assets/CncMill.png';
import Designprocess from '../assets/DesignProcess.png';

const Skills = () => {
  return (
    <div name='skills' className='w-full h-full bg-[#0a192f] text-gray-300'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 justify-center pt-24 text-gray-300 border-gray-300 '>
        <div>
          <p className='text-4xl sm:text-5xl border-gray-300'>CV</p>
        </div>
        <div className='max-w-[1000px] mx-auto px-8 justify-center pt-4 rounded-lg pb-8 text-gray-300 border-gray-300 shadow-lg shadow-[#040c16]'>
          <div>
            <p className='text-2xl sm:text-3xl border-gray-300'>General Information</p>
          </div>
          <div className='max-w-[300px] flex pt-5 justify-between'>
            <p className='text-xl sm:text-2xl font-bold border-gray-300'>Full Name</p>
            <p className='pt-1.5 border-gray-300 align-bottom'>Justin Pullman</p>
          </div>
          <div className='max-w-[386px] flex pt-5 justify-between'>
            <p className='text-xl sm:text-2xl font-bold border-gray-300'>Email</p>
            <p className='pt-1 border-gray-300 align-bottom'>jedisonpullman@gmail.com</p>
          </div>
        </div>
        <div className='max-w-[1000px] mx-auto px-8 justify-center pt-8 rounded-lg  text-gray-300 border-gray-300 shadow-lg shadow-[#040c16]'>
          <div>
            <p className='text-2xl sm:text-3xl border-gray-300 pb-8'>Education</p>
          </div>
          <div className='flex pt-5'>
            <div className='bg-gray-300 text-[#0a192f] max-w-[65px] h-7 sm:h-9 sm:pl-1 sm:pr-1 rounded-lg'>
              <p className='sm:pl-1 text-xl sm:text-2xl font-bold'>2023</p>
            </div>
            <div className='text-left pl-1 sm:pl-32 pb-8'>
              <p className='text-xl sm:text-2xl font-bold'>Bachelor of Science in Aerospace Engineering</p>
              <p className='text-lg '>Iowa State University</p>
            </div>
          </div>
        </div>
        <div className='max-w-[1000px] mx-auto px-8 justify-center pt-8 pb-8 rounded-lg  text-gray-300 border-gray-300 shadow-lg shadow-[#040c16]'>
          <div>
            <p className='text-2xl sm:text-3xl border-gray-300 pb-8'>Experience</p>
          </div>

          <div className=' pt-5 justify-between'>
            <div className='bg-gray-300 text-[#0a192f] max-w-[180px] h-7 sm:h-9 sm:pl-1 sm:pr-1 rounded-lg'>
              <p className='sm:pl-1 text-xl sm:text-2xl font-bold'>2024 to Current</p>

            </div>
            <div className='text-left pl-1 sm:pl-32 '>
              <p className='text-xl sm:text-2xl font-bold sm:pr-[280px]'>3D Modeler - Pullman Enterprises LLC</p>
              <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Collaborated directly with clients to understand project requirements, providing expert guidance on design possibilities and delivering customized 3D modeling solutions.</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Developed detailed 3D models using SolidWorks for various industries, ensuring precision and functionality in line with client specifications.</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Maintained clear and consistent communication with clients throughout project lifecycle, ensuring timely feedback, revisions, and successful delivery of final models.</p>
            </div>
          </div>

          <div className='pt-5 justify-between'>
            <div className='bg-gray-300 text-[#0a192f] max-w-[180px] h-7 sm:h-9 sm:pl-1 sm:pr-1 rounded-lg'>
              <p className='sm:pl-1 text-xl sm:text-2xl font-bold'>2024 to Current</p>
            </div>
            <div className='text-left pl-1 sm:pl-32 '>
              <p className='text-xl sm:text-2xl font-bold sm:pr-[280px]'>Mechanical Engineer - Loveland CreatorSpace</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Designed, prototyped, and developed a custom remote control from scratch, including designing the
casing, programming the microprocessor, and ensuring communication with the main unit; successfully
3D printed prototypes, iterated designs, and delivered a fully functional product as part of a
collaborative team effort</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Maintained and optimized manufacturing equipment, including diagnosing and repairing 3D printers,
operating CNC routers and laser engravers, and designing custom 3D printed hardware; also installed and
managed access control systems to enhance security and operational efficiency</p>
            </div>
          </div>
          
          <div className='pt-5 justify-between'>
            <div className='bg-gray-300 text-[#0a192f] max-w-[180px] h-7 sm:h-9 sm:pl-1 sm:pr-1 rounded-lg'>
              <p className='sm:pl-1 text-xl sm:text-2xl font-bold'>2024 to Current</p>
            </div>
            <div className='text-left pl-1 sm:pl-32 '>
              <p className='text-xl sm:text-2xl font-bold sm:pr-[280px]'>Property Coordinator - Artspace</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Manage key distribution, unit turnovers, move-in inspections, and post-move-in follow-up, ensuring
                timely communication with residents and the Director regarding vacancies, inventory, and office tasks.</p>
            </div>
          </div>

          <div className='pt-5 justify-between'>
            <div className='bg-gray-300 text-[#0a192f] max-w-[65px] h-7 sm:h-9 sm:pl-1 sm:pr-1 rounded-lg'>
              <p className='sm:pl-1 text-xl sm:text-2xl font-bold'>2019</p>
            </div>
            <div className='text-left pl-1 sm:pl-32 '>
              <p className='text-xl sm:text-2xl font-bold sm:pr-[280px]'>Robotics Coach - Peoria Academy</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Instructed 4th – 8th graders in various engineering disciplines to teach the design process and fostered communication through hands-on activities</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Motivated critical thinking by rewarding innovative thinking to garner creative solutions from students</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Established a framework for future team success</p>
            </div>
          </div>

          <div className='pt-5 justify-between'>
            <div className='bg-gray-300 text-[#0a192f] max-w-[65px] h-7 sm:h-9 sm:pl-1 sm:pr-1 rounded-lg'>
              <p className='sm:pl-1 text-xl sm:text-2xl font-bold'>2018</p>
            </div>

            <div className='text-left pl-1 sm:pl-32'>
              <p className='text-xl sm:text-2xl font-bold'>Mechatronics Internship - Caterpillar</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Worked in tandem with industry experts to obtain knowledge about the creation of engine models</p>
                <p className='sm:text-xl text-left sm:pl-10 pb-1'>• Diagnosed and updated customers in service engine control modules</p>
            </div>
          </div>
        </div>
        
        <div className='max-w-[1000px] mx-auto px-8 justify-center pt-8 pb-8 rounded-lg  text-gray-300 border-gray-300 shadow-lg shadow-[#040c16]'>
          <div>
            <p className='text-2xl sm:text-3xl border-gray-300 pb-8'>Leadership and Activities</p>
          </div>

          <div className='text-left sm:pl-10 '>
            <p className='text-xl sm:text-2xl font-bold sm:pr-[280px]'>UAV System Development - Senior Design Project</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Led a 4 person team in the iterative design of a 3 drone, small U.A.V system using SolidWorks</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Designed and 3D printed components for prototyping</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Presented technical material to clients</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-4'>• Conducted design reviews using the round robin method</p>
          </div>

          <hr className='pd-4 mx-auto max-w-[800px]' />

          <div className='text-left sm:pl-10 pt-5'>
            <p className='text-xl sm:text-2xl font-bold'>IGVC Robot - Autonomous Robotics Club (ARC)</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Designed a wheeled robot using Autodesk Inventor for the Intelligent Ground Vehicle Competition</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Compiled product renderings and bill of materials for prospective sponsors</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-4'>• Developed a virtual robot with ROS (robot operating system) and Gazebo</p>
          </div>

          <hr className='pd-4 mx-auto max-w-[800px]' />

          <div className='text-left sm:pl-10 pt-5'>
            <p className='text-xl sm:text-2xl font-bold'>Parking Guidance Prototype</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Developed a prototype that maximizes parking space use for improved user experience</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Implemented C programming to set up an Arduino Mega, incorporating ultrasonic sensors and LED
control, and facilitating communication with a centralized database system</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Demonstrated prototype and presented the need for its implementation</p>
          </div>

          <hr className='pd-4 mx-auto max-w-[800px]' />

          <div className='text-left sm:pl-10 pt-5'>
            <p className='text-xl sm:text-2xl font-bold'>Robotic Arm</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Modified and fabricated an open source 6-axis robotic arm using FDM 3D printing</p>
            <p className='sm:text-xl text-left pl-4 sm:pl-10 pb-1'>• Assembled the robotic arm overcoming unforeseen clearance issues, and improving the design</p>
          </div>
        </div>

        <div className='max-w-[1000px] mx-auto px-8 justify-center pt-8 pb-8 rounded-lg  text-gray-300 border-gray-300 shadow-lg shadow-[#040c16]'>
          <div>
            <p className='text-2xl sm:text-3xl border-gray-300 pb-8'>Skills</p>
          </div>
          <div>
            <p className='text-xl sm:text-2xl border-gray-300 text-center'>CAD</p>
          </div>
          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={SolidworksLogo} alt="TempImage" />
              <p className='my-4'>SolidWorks</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={InventorLogo} alt="TempImage" />
              <p className='my-4'>Autodesk Inventor</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={AnsysLogo} alt="TempImage" />
              <p className='my-4'>Ansys</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-36 mx-auto' src={GDnTLogo} alt="TempImage" />
              <p className='my-4'>GD&T</p>
            </div>
          </div>

          <div>
            <p className='text-xl sm:text-2xl border-gray-300 text-center'>Electrical and Software</p>
          </div>
          <div className='w-full grid grid-cols-2 sm:grid-cols-6 gap-4 text-center py-8'>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-[70px] mx-auto' src={CLogo} alt="TempImage" />
              <p className='my-4'>C</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-[70px] mx-auto' src={CplusLogo} alt="TempImage" />
              <p className='my-4'>C++</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={PythonLogo} alt="TempImage" />
              <p className='my-4'>Python</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-36 mx-auto' src={MatlabLogo} alt="TempImage" />
              <p className='my-4'>MATLAB</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={ArduinoLogo} alt="TempImage" />
              <p className='my-4'>Arduino</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-16 mx-auto' src={RaspberrypiLogo} alt="TempImage" />
              <p className='my-4'>Raspberry Pi</p>
            </div>
          </div>

          <div>
            <p className='text-xl sm:text-2xl border-gray-300 text-center'>Manufacturing</p>
          </div>
          <div className='w-full grid grid-cols-1 sm:grid-cols-3 gap-4 text-center py-8'>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-20 mx-auto' src={TDPrintLogo} alt="TempImage" />
              <p className='my-4'>3D Printing</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-44 mx-auto' src={Cncmill} alt="TempImage" />
              <p className='my-4'>CNC Mill</p>
            </div>
            <div className='grid shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
              <img className='w-28 mx-auto' src={Designprocess} alt="TempImage" />
              <p className='my-4'>Prototyping</p>
            </div>
          </div>
        </div>

        <div className='pb-20'></div>
      </div>
    </div>
  )
}

export default Skills