import React from 'react'
import img1 from '../assets/ARCRobot1.efd174e5d6f3a377a6e0.png'
import img2 from '../assets/Chomper.png'
import img3 from '../assets/Daughtership091523.png'
import img5 from '../assets/MD_ISO_Render.png'
import img6 from '../assets/DaughtershipISO_LoadPaths.png'
import img7 from '../assets/MothershipISO_LoadPath.png'
import img8 from '../assets/SparroConnectionsD.png'
import img9 from '../assets/SparroConnectionsM.png'
import img10 from '../assets/LCSFront.PNG'
import img11 from '../assets/LCSBack.PNG'
import img12 from '../assets/TurtleRenderTopISO.png'
import img13 from '../assets/TurtleRenderBottomISO.png'
import img14 from '../assets/SwerveBotNoBack.png'
import img15 from '../assets/ToyProjectExplodedView.jpeg'
import img16 from '../assets/EggHolderProductRender.jpg'
import img17 from '../assets/Flatpack Render.png'
import img18 from '../assets/MountingBossApartRender.png'
import img19 from '../assets/LipNGrooveApartRender.png'
import img20 from '../assets/SoapBottle_ProductNoBack.jpg'
import img21 from '../assets/Multibody Handtool Render.jpg'
import img22 from '../assets/WeldRender2.jpg'
import img23 from '../assets/CarRemoteControl.png'



const CADDemo = () => {
  return (
    <div name='cad' className='w-full h-full bg-[#0a192f]'>
        
        {/* Container */}
        <div className='grid max-w-[1200px] mx-auto px-8 justify-center pt-24 text-gray-300 border-gray-300 shadow-lg shadow-[#040c16]'>
            <div className='text-center'>
                    <p className='text-2xl sm:text-4xl font-bold inline border-b-4 text-gray-300 border-gray-300'>CAD Projects and Renders</p>
            </div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img1} alt="img1" />
                <img src={img2} alt="img2" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>[Left] Robot designed to maximize COTS parts and 3D printing. [Right] Item from TV show redesigned to be a koozie for 12oz cans.</p>
            </div>
            <div className='pb-24'></div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img3} alt="img3" />
                <img src={img5} alt="img5" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>Senior design project - designed a 3 drone sUAV system to complete autonomous missions. Designed in SolidWorks</p>
            </div>
            <div className='pb-24'></div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img6} alt="img6" />
                <img src={img7} alt="img7" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>A render of the load paths for each drone. Designed in SolidWorks and rendered in Keyshot</p>
            </div>
            <div className='pb-24'></div>
        
            <div style={{width: '50%'}} className='flex mx-auto justify-center'>
                <img src={img8} alt="img6" />
                <img src={img9} alt="img7" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>A render of the connection points in each drone. Designed in SolidWorks and rendered in Keyshot</p>
            </div>
            <div className='pb-24'></div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img10} alt="img6" />
                <img src={img11} alt="img7" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>A commisioned custom remote for the DDA of Loveland, CO. Designed in SolidWorks.</p>
            </div>
            <div className='pb-24'></div>
            
            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img12} alt="img6" />
                <img src={img13} alt="img7" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>A toy tortuga created for Dragonstone Heroes. Designed in SolidWorks and rendered in Blender.</p>
            </div>
            <div className='pb-24'></div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img14} alt="img6" />
                <img src={img15} alt="img7" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>[Left] New Drive train developed for a client. [Right] Toy created to teach about elements. Designed in SolidWorks, rendered in Keyshot</p>
            </div>
            <div className='pb-24'></div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img16} alt="img6" />
                <img src={img17} alt="img7" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>[Left] Recreated a pelican case with capacity for one egg. [Right] Flatpack table. Both designed in SolidWorks, rendered in Keyshot.</p>
            </div>
            <div className='pb-24'></div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img18} alt="img6" />
                <img src={img19} alt="img7" />
            </div>

            <div className='flex mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>[Left] Injection molded case with screw posts. [Right] Deoderant case. Both designed in SolidWorks, rendered in Keyshot.</p>
            </div>
            <div className='pb-24'></div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img20} alt="img6" />
                <img src={img21} alt="img7" />
            </div>

            <div className='mx-auto justify-center'>
                <p className='sm:text-xl pt-4'>[Left] Soap dispensor. [Right] Screw driver.</p>
                <p className='sm:text-xl pt-4'>Both designed in SolidWorks and rendered in Keyshot.</p>
            </div>
            <div className='pb-24'></div>

            <div style={{width: '40%'}} className='flex mx-auto justify-center'>
                <img src={img22} alt="img22" />
                <img src={img23} alt="img23" />
            </div>

            <div className=' mx-auto justify-center' >
                <p className='sm:text-xl pt-4'>[Left] Welded Stand. [Right] Car remote case.</p>
                <p className='sm:text-xl pt-4'>Both designed in SolidWorks and rendered in Keyshot.</p>
            </div>
            <div className='pb-24'></div>
            
            
        </div>
    </div>
  )
}

export default CADDemo